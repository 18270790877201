import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// // utilities routing
const ContactUs = Loadable(lazy(() => import('views/contact-us')));

const FollowUsRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'contact-us',
            element: <ContactUs />,
        },
    ],
};

export default FollowUsRoutes;
