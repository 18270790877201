import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// // utilities routing
const WhoWeAre = Loadable(lazy(() => import('views/about/who-we-are')));
const WhatWeOffer = Loadable(lazy(() => import('views/about/what-we-offer')));
const People = Loadable(lazy(() => import('views/about/people')));
const Partners = Loadable(lazy(() => import('views/about/partners')));
const Project1 = Loadable(lazy(() => import('views/about/project1')));
const Project2 = Loadable(lazy(() => import('views/about/project2')));
const Project3 = Loadable(lazy(() => import('views/about/project3')));
const Project4 = Loadable(lazy(() => import('views/about/project4')));
const Project5 = Loadable(lazy(() => import('views/about/project5')));
const Project6 = Loadable(lazy(() => import('views/about/project6')));
const Project7 = Loadable(lazy(() => import('views/about/project7')));

// ==============================|| MAIN ROUTING ||============================== //

const AboutRoutes = {
    path: '/about',
    element: <MainLayout />,
    children: [
        {
            path: 'who-we-are',
            element: <WhoWeAre />,
        },
        {
            path: 'what-we-offer',
            element: <WhatWeOffer />,
        },
        {
            path: 'people',
            element: <People />,
        },
        {
            path: 'partners',
            element: <Partners />,
        },
        {
            path: 'project-1',
            element: <Project1 />,
        },
        {
            path: 'project-2',
            element: <Project2 />,
        },
        {
            path: 'project-3',
            element: <Project3 />,
        },
        {
            path: 'project-4',
            element: <Project4 />,
        },
        {
            path: 'project-5',
            element: <Project5 />,
        },
        {
            path: 'project-6',
            element: <Project6 />,
        },
        {
            path: 'project-7',
            element: <Project7 />,
        }
    ],
};

export default AboutRoutes;
