import { Box, Tooltip, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import Footer from 'ui-component/Footer';

import bg2 from '../../../assets/images/aq101-bg-2.webp';
import {
    Start,
    TableOfContents,
    Content,
    InfoCard,
    Scroll,
    ContentHeadingTypography,
    ContentBodyTypography,
} from '../air-quality-101-subpage-theme';
import { useState } from 'react';
// import { useEffect } from 'react';
// import { useRef } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const MoreInfo = ({ info, more_info }) => {
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };
    return (
        <Tooltip
            PopperProps={{
                disablePortal: true,
            }}
            // placement="bottom-start"
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            // disableHoverListener
            disableTouchListener
            title={
                <Typography
                    sx={{
                        fontSize: { xs: '1rem' },
                        letterSpacing: '0.05rem',
                        lineHeight: { xs: '1.5rem' },
                        padding: { lg: '0.5rem' },
                    }}
                >
                    {more_info}
                </Typography>
            }
            arrow
        >
            <Typography
                sx={{
                    fontSize: { xs: '1rem' },
                    letterSpacing: '0.05rem',
                    lineHeight: { xs: '1.5rem' },
                    cursor: 'pointer',
                }}
                onClick={handleTooltipOpen}
                // onClick={() => setOpen((prev) => !prev)}
            >
                {info}
            </Typography>
        </Tooltip>
    );
    // const [open, setOpen] = useState(false);
    // const tooltipRef = useRef(null); // Reference for the tooltip

    // const handleTooltipClose = () => {
    //     setOpen(false);
    // };

    // const handleClickAnywhere = (event) => {
    //     // Check if the click is outside the tooltip
    //     if (tooltipRef.current && !tooltipRef.current.contains(event.target) && open) {
    //         handleTooltipClose();
    //     }
    // };

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickAnywhere);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickAnywhere);
    //     };
    // }, [open]);

    // return (
    //     <Tooltip
    //         PopperProps={{
    //             disablePortal: true,
    //             ref: tooltipRef, // Attach the ref to the tooltip
    //         }}
    //         open={open}
    //         title={more_info}
    //         arrow
    //     >
    //         <Typography
    //             sx={{
    //                 fontSize: { xs: '1rem' },
    //                 letterSpacing: '0.05rem',
    //                 lineHeight: { xs: '1.5rem' },
    //                 cursor: 'pointer',
    //             }}
    //             onClick={() => setOpen((prev) => !prev)}
    //         >
    //             {info}
    //         </Typography>
    //     </Tooltip>
    // );
};

const AQ101_Subpage = () => {
    const tooltipContent = {
        pm: {
            content: (
                <>
                    Particulate matter can be made up of a variety of components including nitrates, sulfates, organic
                    chemicals, metals, soil or dust particles, and allergens (such as fragments of pollen or mold
                    spores).
                    <br />
                    <br />
                    Particle pollution mainly comes from motor vehicles, wood burning heaters and industry. During
                    bushfires or dust storms, particle pollution can reach extremely high concentrations.
                </>
            ),
        },
        pm10: {
            content:
                'Particles with a diameter of 10 micrometers or less. Once inhaled, these particles can affect the heart and lungs and cause serious health effects.',
        },
        pm25: {
            content: 'Particles with a diameter of 2.5 micrometers or less. Note that PM10 includes PM2.5.',
        },
        ozone: {
            content:
                'Ground level ozone is the main component of smog. It comes from the interaction between sunlight and emissions from sources such as motor vehicles and industry. It can travel long distances and accumulate to high concentrations far away from the sources of the original pollutants. It can be harmful even at low levels of concentration.',
        },
        voc: {
            content:
                'VOC vaporize at or near room temperature—hence, the designation volatile. They are called organic because they contain carbon. VOCs are given off by paints, cleaning supplies, pesticides, some furnishings, and even craft materials like glue. Gasoline and natural gas are major sources of VOCs, which are released during combustion.',
        },
        pah: {
            content:
                'PAH are organic compounds containing carbon and hydrogen. Of more than 100 PAHs known to be widespread in the environment, 15 are listed in the Report on Carcinogens. In addition to combustion, many industrial processes, such as iron, steel, and rubber product manufacturing, as well as power generation, also produce PAHs as a by-product. PAHs are also found in particulate matter.',
        },
    };
    return (
        <>
            <Helmet>
                <title>Common Air Pollutants | UP CARE</title>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    position: 'relative',
                }}
            >
                <Box>
                    {/* Start */}
                    <Start
                        title={'Next, we have the Common Air Pollutants.'}
                        text={
                            'The World Health Organization (WHO) and the US Environmental Protection Agency (EPA) report that the top 5 air pollutants (PM, CO, SO2, NOx, O3) account for over 90% of global air pollution emissions.'
                        }
                    />
                    {/* TOC for small screens */}
                    <Box display={{ xs: 'flex', md: 'none' }}>
                        <TableOfContents />
                    </Box>
                    {/* Content */}
                    <Content
                        bg={bg2}
                        children={
                            <>
                                <InfoCard
                                    isTopBox={true}
                                    children={
                                        <>
                                            <MoreInfo
                                                info={
                                                    <ContentHeadingTypography>
                                                        Particulate Matter (PM) <InfoOutlinedIcon fontSize="medium" />
                                                    </ContentHeadingTypography>
                                                }
                                                more_info={tooltipContent.pm.content}
                                            />
                                            <ContentBodyTypography>
                                                <br />
                                                Also known as particle pollution, these are tiny solid particles and
                                                liquid droplets suspended in air that can penetrate the lungs, and if
                                                small enough, even the bloodstream.
                                            </ContentBodyTypography>
                                            <MoreInfo
                                                info={
                                                    <ContentBodyTypography>
                                                        <br />•{' '}
                                                        <b>
                                                            PM 10 <InfoOutlinedIcon fontSize="small" />
                                                        </b>
                                                    </ContentBodyTypography>
                                                }
                                                more_info={tooltipContent.pm10.content}
                                            />
                                            <ContentBodyTypography>
                                                &nbsp;&nbsp;&nbsp;&nbsp;Small enough to pass through the throat and nose
                                                and enter the lungs.
                                            </ContentBodyTypography>
                                            <MoreInfo
                                                info={
                                                    <ContentBodyTypography>
                                                        <br />•{' '}
                                                        <b>
                                                            PM 2.5 <InfoOutlinedIcon fontSize="small" />
                                                        </b>
                                                    </ContentBodyTypography>
                                                }
                                                more_info={tooltipContent.pm25.content}
                                            />
                                            <ContentBodyTypography>
                                                &nbsp;&nbsp;&nbsp;&nbsp;30 times thinner than a human hair, so small
                                                they can get deep into the lungs and into the bloodstream. Exposure to
                                                PM2.5 over long periods (years) can cause adverse health effects.
                                            </ContentBodyTypography>
                                        </>
                                    }
                                />
                                <InfoCard
                                    variant={0}
                                    children={
                                        <>
                                            <MoreInfo
                                                info={
                                                    <ContentHeadingTypography>
                                                        Ozone (O3) <InfoOutlinedIcon fontSize="medium" />
                                                    </ContentHeadingTypography>
                                                }
                                                more_info={tooltipContent.ozone.content}
                                            />
                                            <ContentBodyTypography>
                                                <br />
                                                In the upper atmosphere, it protects us from UV radiation from the sun.
                                                But at ground level, it is harmful and linked to respiratory problems.
                                            </ContentBodyTypography>
                                        </>
                                    }
                                />
                                <InfoCard
                                    title={'Noxious gases (CO2, CO, NOx,  SOx)'}
                                    body={
                                        <>
                                            <br />
                                            Includes carbon dioxide (CO2), carbon monoxide (CO), nitrogen oxides (NOx),
                                            and sulfur oxides (SOx). These are components of motor vehicle emissions and
                                            byproducts of industrial processes.
                                        </>
                                    }
                                />
                                <InfoCard
                                    variant={0}
                                    title={'Other Pollutants'}
                                    children={
                                        <>
                                            <MoreInfo
                                                info={
                                                    <ContentBodyTypography>
                                                        <br />•{' '}
                                                        <b>
                                                            Volatile organic compounds (VOC){' '}
                                                            <InfoOutlinedIcon fontSize="small" />
                                                        </b>
                                                    </ContentBodyTypography>
                                                }
                                                more_info={tooltipContent.voc.content}
                                            />
                                            <MoreInfo
                                                info={
                                                    <ContentBodyTypography>
                                                        <br />•{' '}
                                                        <b>
                                                            Polycyclic aromatic hydrocarbons (PAH){' '}
                                                            <InfoOutlinedIcon fontSize="small" />
                                                        </b>
                                                    </ContentBodyTypography>
                                                }
                                                more_info={tooltipContent.pah.content}
                                            />
                                        </>
                                    }
                                />
                                <InfoCard
                                    variant={2}
                                    body={
                                        <>
                                            💡 <b>Traffic-Related Air Pollution (TRAP)</b>, a mixture of gases and
                                            particles, has most of the elements of human-made air pollution:
                                            ground-level ozone, various forms of carbon, nitrogen oxides, sulfur oxides,
                                            volatile organic compounds, polycyclic aromatic hydrocarbons, and fine
                                            particulate matter.
                                        </>
                                    }
                                />
                            </>
                        }
                    />
                    <Scroll />
                </Box>
                {/* TOC for large screens */}
                <Box display={{ xs: 'none', md: 'flex' }}>
                    <TableOfContents />
                </Box>
            </Box>
            <Footer />
        </>
    );
};

export default AQ101_Subpage;
