import { useRoutes } from 'react-router-dom';

// routes
import AboutRoutes from './AboutRoutes';
import FollowUsRoutes from './FollowUsRoutes';
import KeyEventsRoutes from './KeyEventsRoutes';
import LandingRoutes from './LandingRoutes';
import OtherRoutes from './OtherRoutes';
import AboutUsRoutes from './AboutUsRoutes';
import BeInvolvedRoutes from './BeInvolvedRoutes';
import ResourcesRoutes from './ResourcesRoutes';
import SolutionsRoutes from './SolutionsRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([AboutRoutes, LandingRoutes, FollowUsRoutes, KeyEventsRoutes, OtherRoutes, AboutUsRoutes, BeInvolvedRoutes, ResourcesRoutes, SolutionsRoutes]);
}
