import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from 'ui-component/Header';

import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';

// assets
import { IconChevronRight } from '@tabler/icons-react';

import '../../ui-component/CookieConsent/theme_upcare.css';
import CookieConsent from '../../ui-component/CookieConsent';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...{
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        padding: 0,
        backgroundColor: 'white',
        width: `calc(100% - ${drawerWidth}px)`,
    },
}));

// ==============================|| MAIN LAYOUT ||============================== //

const LandingLayout = () => {
    const theme = useTheme();

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                elevation={5}
                color='transparent'
                sx={{
                    backgroundColor: 'rgba(0, 135, 141, 0.7)',
                    backdropFilter: 'blur(10px)',
                }}
            >
                <Container maxWidth="xl">
                    <Toolbar>
                        <Header />
                    </Toolbar>
                </Container>
            </AppBar>

            {/* main content */}

            <Main theme={theme}>
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
                
            </Main>
            <CookieConsent />
        </Box>
    );
};

export default LandingLayout;
