import { Box, Button, styled, Typography } from '@mui/material';
import { List, ListItem, ListItemText } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { airqualityTheme, marginDesktopBetween } from './air-quality-101-theme';
import { marginMobile, marginDesktop } from './air-quality-101-theme';
import ListItemIcon from '@mui/material/ListItemIcon';

import React from 'react';
import { Link } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import bgTOC from '../../assets/images/aq101-bg-toc.webp';
import bgTOCdesktop from '../../assets/images/aq101-bg-toc-desktop.webp';

import AeonMascot from '../../assets/images/aeon-mascot.webp';

import { useState, useEffect } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fab from '@mui/material/Fab';

export const SectionTitleTypography = styled(Typography)(({ theme }) => ({
    fontSize: '1.25rem',
    lineHeight: '130%',
    marginBottom: '1.25rem',
    color: airqualityTheme.palette.secondary.contrastText,
    [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
        color: airqualityTheme.palette.secondary.main,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '2rem',
    },
    fontWeight: 600,
}));

export const SectionSubheadingTypography = styled(Typography)(({ theme }) => ({
    fontSize: '1.15rem',
    lineHeight: '1.75rem',
    color: airqualityTheme.palette.secondary.contrastText,
    [theme.breakpoints.up('md')]: {
        fontSize: '1.25rem',
        color: airqualityTheme.palette.secondary.main,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '1.5rem',
    },
    fontWeight: 500,
}));

export const ContentHeadingTypography = styled(Typography)(({ theme }) => ({
    fontSize: '1.15rem',
    [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '1.75rem',
    },
    fontWeight: 700,
}));

export const ContentBodyTypography = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    letterSpacing: '0.05rem',
    lineHeight: '1.5rem',
    [theme.breakpoints.up('md')]: {
        fontSize: '1.25rem',
        lineHeight: '2rem',
    },
}));

const ToC = () => {
    const location = useLocation(); // Get the current URL
    const sections = [
        {
            title: 'Introduction to Air Pollution',
            link: '/resources/air-quality-101/introduction-to-pollution',
        },
        {
            title: 'Common Air Pollutants',
            link: '/resources/air-quality-101/common-air-pollutants',
        },
        {
            title: 'Understanding Air Quality',
            link: '/resources/air-quality-101/understanding-air-quality',
        },
        {
            title: 'Air Quality Monitoring (AQM)',
            link: '/resources/air-quality-101/air-quality-monitoring',
        },
        {
            title: 'Importance of AQM',
            link: '/resources/air-quality-101/importance-of-aqm',
        },
        {
            title: 'Summary and References',
            link: '/resources/air-quality-101/summary-and-references',
        },
    ];
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: { xs: '2rem', sm: 'clamp(2.5rem, 15%, 100%)', md: '2.5rem' },
            }}
        >
            <List>
                {sections.map((section, index) => {
                    // Check if the current section matches the current page
                    const isActive = location.pathname === section.link;

                    return (
                        <ListItem
                            key={index}
                            button
                            component={Link}
                            href={section.link}
                            sx={{
                                textDecoration: 'none',
                                '&:hover': {
                                    color: airqualityTheme.palette.primary.contrastText,
                                },
                            }}
                        >
                            <ListItemIcon>
                                <LocationOnIcon
                                    sx={{ color: isActive ? airqualityTheme.palette.primary.contrastText : 'inherit' }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={section.title}
                                primaryTypographyProps={{
                                    fontSize: '1.25rem',
                                    fontWeight: isActive ? 700 : 'inherit',
                                }}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};

export const TableOfContents = () => {
    return (
        <Box
            sx={{
                position: { md: 'sticky' },
                right: '0px',
                top: '75px',
                display: 'flex',
                flexDirection: 'column',
                width: { xs: '100%', md: '375px' },
                height: { xs: '550px', sm: 'clamp(600px, 75vw, 700px)', md: '725px' },
                backgroundImage: { xs: `url(${bgTOC})`, md: `url(${bgTOCdesktop})` },
                backgroundSize: '100%',
                backgroundPosition: 'top center',
                backgroundRepeat: 'no-repeat',
                paddingTop: { xs: '14%', sm: '5.5rem', md: '3rem' },
                marginRight: { md: marginDesktopBetween, lg: marginDesktop },
                marginBottom: { md: marginDesktopBetween, lg: marginDesktop },
            }}
        >
            <Button
                variant="text"
                endIcon={<ArrowRightIcon />}
                href="/resources/air-quality-101"
                sx={{
                    fontSize: '1.15rem',
                    marginTop: { xs: '-3rem', md: '0' },
                    marginBottom: { xs: '3rem', md: '2rem' },
                    color: airqualityTheme.palette.primary.contrastText,
                    fontWeight: 700,
                    textDecoration: 'underline', // text is underlined
                    textTransform: 'none', // prevent uppercase transformation
                    '& .MuiButton-endIcon': {
                        textDecoration: 'none', // icon isn't underlined
                    },
                    '&:hover': {
                        backgroundColor: '#0D797E',
                        color: airqualityTheme.palette.primary.contrastText,
                    },
                }}
            >
                Air Quality 101
            </Button>
            <ToC />
        </Box>
    );
};

export const Start = ({ title, text }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                background: { xs: '#00878D', md: 'transparent' },
                pt: { xs: 8, md: 10 },
                paddingLeft: { xs: marginMobile, sm: marginDesktopBetween, lg: marginDesktop },
                paddingRight: { xs: marginMobile, sm: marginDesktopBetween, md: marginMobile },
                pb: marginDesktopBetween,
                marginBottom: { xs: '-8px', md: '50px' },
                marginTop: '-3px', // prevent whitespace right below navbar
            }}
        >
            <Box
                component="img"
                src={AeonMascot}
                alt="CARE Node"
                sx={{
                    position: 'absolute',
                    top: { xs: '15%', lg: '20%' },
                    right: { xs: '62%', lg: '56%' },
                    width: {
                        xs: 'clamp(70px, 28%, 115px)',
                        lg: 'clamp(250px, 20%, 300px)',
                    },
                    maxWidth: '100%',
                    objectFit: 'cover',
                }}
            />
            <Box
                sx={{
                    marginRight: { sm: '7%', md: '0' },
                    minWidth: { xs: '60%', sm: '57%', md: '60%' },
                    maxWidth: '100%',
                    width: { xs: '26ch', sm: '15ch', md: '30ch', lg: '40ch', xl: '60ch' },
                    height: { xs: '5.5rem', md: '7rem', lg: '8rem' },
                }}
            >
                <SectionTitleTypography>{title}</SectionTitleTypography>
            </Box>
            <Box
                sx={{
                    bgcolor: { xs: 'rgba(0, 0, 0, 0.35)', md: 'transparent' },
                    paddingY: { xs: '1.5rem', md: '0.5rem' },
                    paddingX: { xs: '1.5rem', sm: marginDesktopBetween, md: '0' },
                    marginRight: { sm: 'clamp(1rem, 7%, 10%)', md: '0' },
                    marginLeft: { sm: 'clamp(1rem, 7%, 10%)' },
                    borderRadius: '10px',
                    width: {
                        lg: 'clamp(60%, 26ch, 100%)',
                    },
                }}
            >
                <SectionSubheadingTypography>{text}</SectionSubheadingTypography>
            </Box>
        </Box>
    );
};

export const Content = ({ bg, children }) => {
    return (
        <Box
            sx={{
                position: 'relative', // for stacking the sticky bg and content
            }}
        >
            <Box
                sx={{
                    display: { xs: 'none', md: 'block' },
                    position: 'sticky',
                    top: marginDesktop,
                    left: { md: marginDesktopBetween, lg: marginDesktop }, // left margin
                    width: { md: `calc(100vw - 4rem)`, lg: `calc(100vw - 8rem)` }, // left margin, marginDesktop=8rem, mDBetween=4rem
                    marginRight: `calc(-375px - 8rem)`, // Pushes it outside the container, -(TOC + marginDesktop)
                    marginBottom: '4rem',
                    height: '80vh',
                    background: `url(${bg})`,
                    opacity: '70%',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: '100px 0px 0px 30px',
                    overflow: 'hidden',
                    zIndex: 0,
                }}
            />
            <Box
                sx={{
                    position: 'relative',
                    zIndex: 1, // content above the background
                    marginTop: { sm: marginDesktopBetween, md: '-70vh' }, // overlap the sticky bg initially, with large room overhead
                    marginBottom: { sm: marginDesktopBetween, md: marginDesktop },
                    marginLeft: { sm: marginDesktopBetween, lg: marginDesktop },
                    marginRight: { sm: marginDesktopBetween, md: '0' },
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export const InfoCard = ({ title, body, variant = 1, isTopBox = false, children }) => {
    const boxcolor =
        variant === 1
            ? 'rgba(217, 217, 217,0.85)' // light gray
            : variant === 2
            ? airqualityTheme.palette.primary.dark // dark teal
            : 'rgba(211, 223, 227,0.85)'; // default, light blue
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                color: variant === 2 ? '#fff' : '#000',
                bgcolor: boxcolor,
                paddingX: '1.5rem',
                paddingY: { xs: '2rem', lg: '3rem' },
                marginLeft: { xs: marginMobile, lg: marginDesktopBetween },
                marginRight: marginMobile,
                marginY: '3rem',
                borderRadius: { xs: '10px', md: isTopBox ? '50px 10px 10px 10px' : '10px' },
            }}
        >
            <ContentHeadingTypography>{title}</ContentHeadingTypography>
            <ContentBodyTypography>{body}</ContentBodyTypography>
            {children}
        </Box>
    );
};

export const Scroll = () => {
    const showBelow = 500; // threshold
    const [show, setShow] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);
    let scrollTimeout = null;

    const handleScroll = () => {
        if (window.scrollY > showBelow) {
            setShow(true); // Show the button when scrolled below the threshold
        } else {
            setShow(false); // Hide the button when above the threshold
        }

        setIsScrolling(true);

        // Clear any previous timeout
        if (scrollTimeout) {
            clearTimeout(scrollTimeout);
        }

        // Set a timeout to hide the button after 2 seconds of inactivity
        scrollTimeout = setTimeout(() => {
            setIsScrolling(false);
        }, 2000);
    };

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (showBelow) {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
                if (scrollTimeout) clearTimeout(scrollTimeout); // Cleanup the timeout
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        show &&
        isScrolling && (
            <Fab
                onClick={handleClick}
                aria-label="to top"
                sx={{
                    display: { md: 'none' },
                    zIndex: 2,
                    position: 'fixed',
                    bottom: '2vh',
                    right: '5%',
                }}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        )
    );
};
