import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// // utilities routing
const Government = Loadable(lazy(() => import('views/sample-page')));
const Researchers = Loadable(lazy(() => import('views/sample-page')));
const Public = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const SolutionsRoutes = {
    path: '/solutions',
    element: <MainLayout />,
    children: [
        {
            path: 'government',
            element: <Government />,
        },
        {
            path: 'researchers',
            element: <Researchers />,
        },
        {
            path: 'public',
            element: <Public />,
        },
    ],
};

export default SolutionsRoutes;
