import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    Drawer,
    List,
    ListItemButton,
    ListItemText,
    Typography,
    useMediaQuery,
} from '@mui/material';

// third-party
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import LogoSection from '../LogoSectionLanding';
import HoverMenuButton from 'ui-component/HoverMenuButtonLanding';
import NestedList from 'ui-component/NestedList';
// assets
import { IconMenu2 } from '@tabler/icons-react';
import { drawerWidth } from 'store/constant';
import { PagesList } from 'utils/contents';
// import logo from '../../assets/images/UPCARE_knockout.webp';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
    const customization = useSelector((state) => state.customization);

    const navigate = useNavigate();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDataViewerButtonClick = () => {
        // Get the current window URL
        const currentUrl = window.location.href;

        // Set the default URL
        let newUrl = 'https://graph.up-care.org';

        // Check if the current URL contains "upcare.ph"
        if (currentUrl.includes('upcare.ph')) {
            newUrl = 'https://graph.upcare.ph';
        }

        // Open the new URL in a new tab
        window.open(newUrl, '_blank');
    };

    const handleApiButtonClick = () => {
        // Get the current window URL
        const currentUrl = window.location.href;

        // Set the default URL
        let newUrl = 'https://api.up-care.org';

        // Check if the current URL contains "upcare.ph"
        if (currentUrl.includes('upcare.ph')) {
            newUrl = 'https://api.upcare.ph';
        }

        // Open the new URL in a new tab
        window.open(newUrl, '_blank');
    };

    const MenuList = () => {
        const items = PagesList.map((item, i) => {
            if (item.type === 'link') {
                return (
                    <ListItemButton
                        key={i}
                        sx={{
                            borderRadius: '0px',
                            alignItems: 'flex-start',
                            color: 'grey.100',
                            '&:hover': {
                                bgcolor: 'transparent',
                                borderBottom: '2px solid',
                                borderColor: 'grey.100',
                            },
                            mb: 0.5,
                            py: 1.25,
                            pl: '24px',
                        }}
                        onClick={() => {
                            navigate(item.path, { replace: false });
                        }}
                        disabled={item.disabled}
                    >
                        <ListItemText primary={<Typography color="grey.100">{item.name}</Typography>} />
                    </ListItemButton>
                );
            } else if (item.type === 'menu') {
                return <NestedList key={i} title={item.name} menuItems={item.children} />;
            }
            return null;
        });

        return <List>{items}</List>;
    };

    const MenuButtons = () => {
        const items = PagesList.map((item, i) => {
            if (item.type === 'link') {
                return (
                    <Button
                        key={i}
                        sx={{
                            borderRadius: '0px',
                            alignItems: 'flex-start',
                            backgroundColor: 'inherit',
                            color: 'grey.100',
                            pb: 0.25,
                            px: 0,
                            mx: 1,
                            '&:hover': {
                                borderBottom: '2px solid',
                            },
                        }}
                        onClick={() => {
                            navigate(item.path, { replace: false });
                        }}
                        disableRipple
                        disabled={item.disabled}
                    >
                        <Typography color="inherit">{item.name}</Typography>
                    </Button>
                );
            } else if (item.type === 'menu') {
                return <HoverMenuButton key={i} title={item.name} menuItems={item.children} />;
            }
            return null;
        });

        return <List>{items}</List>;
    };

    const drawer = (
        <>
            <Box sx={{ display: 'flex', p: 2, ml: 2, mt: 1 }}>
                <LogoSection />
            </Box>

            <BrowserView
                style={{
                    height: !matchUpLg ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                }}
            >
                <MenuList />
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                </Box>
            </MobileView>
        </>
    );
    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto',
                    },
                }}
            >
                {isLargeScreen && (
                    <>
                        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', mr: 2 }} aria-label="Menu">
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    transition: 'all .2s ease-in-out',
                                    background: theme.palette.primary.light,
                                    color: theme.palette.primary.dark,
                                    '&:hover': {
                                        background: theme.palette.primary.dark,
                                        color: theme.palette.primary.light,
                                    },
                                }}
                                onClick={toggleDrawer(true)}
                                color="inherit"
                            >
                                <IconMenu2 stroke={1.5} size="1.3rem" />
                            </Avatar>
                        </ButtonBase>
                        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: 'auto' }} aria-label="mailbox folders">
                            <Drawer
                                variant="temporary"
                                anchor="left"
                                open={drawerOpen}
                                onClose={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}
                                sx={{
                                    '& .MuiDrawer-paper': {
                                        width: drawerWidth,
                                        backgroundColor: 'rgba(0, 135, 141, 0.7)',
                                        backdropFilter: 'blur(10px)',
                                        color: 'grey.100',
                                        borderRight: 'none',
                                    },
                                }}
                                ModalProps={{ keepMounted: true }}
                                color="inherit"
                            >
                                {drawer}
                            </Drawer>
                        </Box>
                    </>
                )}
                <Box component="span" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                    <LogoSection />
                </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            {!isLargeScreen && (
                <>
                    <Box sx={{ flexGrow: 1 }} />
                    <MenuButtons />
                </>
            )}
            <Box sx={{ ml: 1 }}>
                <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                        borderRadius: `${customization.borderRadius}px`,
                        color: 'common.white',
                        minWidth: '80px',
                    }}
                    onClick={handleDataViewerButtonClick}
                >
                    Data Viewer
                </Button>
            </Box>
            <Box sx={{ ml: 1 }}>
                <Button
                    color="primary"
                    variant="contained"
                    sx={{
                        borderRadius: `${customization.borderRadius}px`,
                        backgroundColor: 'grey.100',
                        minWidth: '80px',
                        '&:hover': {
                            backgroundColor: 'grey.300',
                        },
                    }}
                    onClick={handleApiButtonClick}
                >
                    Login
                </Button>
            </Box>
        </>
    );
};

export default Header;
