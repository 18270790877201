import { Link as LinkDOM, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    ButtonBase,
    Collapse,
    Container,
    Grid,
    Link,
    List,
    ListItemButton,
    Typography,
    useMediaQuery,
} from '@mui/material';

import logoKnockout from '../../assets/images/upcareKnockout.webp';
import AirAwareKnockout from '../../assets/images/AirAwareKnockout.webp';
import { useState } from 'react';
import { FooterSections } from 'utils/contents';

// ==============================|| DEFAULT Footer ||============================== //
// styles

const Footer = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const FacebookIcon = () => (
        <svg width="30" height="30" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                id="Vector"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.942 17.6814C33.942 8.42142 26.4804 0.913086 17.2754 0.913086C8.07037 0.913086 0.608704 8.42142 0.608704 17.6814C0.608704 26.0514 6.7037 32.9881 14.672 34.2464V22.5298H10.4387V17.6798H14.672V13.9881C14.672 9.78475 17.1587 7.46309 20.967 7.46309C22.7904 7.46309 24.697 7.79142 24.697 7.79142V11.9181H22.597C20.5254 11.9181 19.8804 13.2098 19.8804 14.5348V17.6814H24.502L23.7637 22.5281H19.8804V34.2464C27.847 32.9881 33.942 26.0514 33.942 17.6814Z"
                fill="white"
            />
        </svg>
    );

    const [items, setItems] = useState(FooterSections.map((item) => {
        return {
            ...item,
            show: false
        }
    }))

    const showItem = (itemIndex) => {
        const itemsBuffer = items.map((item, idx) => {
            return {
                ...item,
                show: idx === itemIndex ? !item.show : item.show,
            }
        })
        setItems(itemsBuffer)
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    bgcolor: theme.palette.primary.dark,
                }}
            >
                <Container maxWidth="xl" sx={{ pt: 5, pb: 2 }}>
                    {!isSmallScreen && <Grid
                        container
                        direction="row"
                        display='flex'
                        justifyContent="center"
                        columnGap={10}
                        rowGap={2}
                        sx={{ pb: 4 }}
                    >
                        {FooterSections.map((item) => {
                            return (
                                <Grid
                                    container
                                    direction="column"
                                    alignItems='flex-start'
                                    justifyContent="center"
                                    md='auto'
                                    rowGap={1}
                                >
                                    <Typography
                                        variant="body1"
                                        component="span"
                                        fontWeight="bold"
                                        color="#E69E4D"
                                        sx={{ fontSize: '1rem' }}
                                        textAlign='center'
                                        textTransform='uppercase'
                                    >
                                        {item.section}
                                    </Typography>
                                    {item.subsections.map((subsection) => {
                                        return (
                                            <Grid item display='flex' flexDirection='column' rowGap={1} columnGap={1} alignItems='flex-start'>
                                                <ButtonBase
                                                    disableRipple
                                                    component={LinkDOM}
                                                    to={subsection.to}
                                                    disabled={subsection.to === '#'}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        fontWeight="normal"
                                                        color={subsection.to === '#' ? "#B5B5B3" : "grey.100"}
                                                        sx={{ fontSize: '1rem' }}
                                                    >
                                                        {subsection.title}
                                                    </Typography>
                                                </ButtonBase>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            )
                        })}
                    </Grid>}

                    {isSmallScreen && (
                        <Grid
                            container
                            direction="row"
                            display='flex'
                            justifyContent="center"
                            sx={{ pb: 1 }}
                        >
                            <ButtonBase
                                disableRipple
                                component={LinkDOM}
                                to={'/'}
                            >
                                <Box
                                    component="img"
                                    src={logoKnockout}
                                    alt="CARE"
                                    sx={{
                                        height: '40px',
                                    }}
                                />
                            </ButtonBase>
                        </Grid>
                    )}

                    {isSmallScreen && <Grid
                        container
                        direction="row"
                        display='flex'
                        justifyContent="center"
                        columnGap={10}
                        rowGap={2}
                        sx={{ pb: 1 }}
                    >
                        <List>
                            {items.map((item, idx) => {
                                return (
                                    <>
                                        <ListItemButton
                                            sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', '&:hover': { bgcolor: 'transparent' } }}
                                        >
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                fontWeight="bold"
                                                color="#E69E4D"
                                                sx={{ fontSize: '1rem' }}
                                                textAlign='center'
                                                textTransform='uppercase'
                                                onClick={() => {showItem(idx)}}
                                            >
                                                {item.section}
                                            </Typography>
                                        </ListItemButton>
                                        <Collapse in={item.show} timeout="auto" unmountOnExit>
                                            <List component='div' disablePadding>
                                                {item.subsections.map((subsection) => {
                                                    return (
                                                        <ListItemButton
                                                            sx={{
                                                                textAlign: 'center',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                '&:hover': { bgcolor: 'transparent' }
                                                            }}
                                                            onClick={() => {navigate(subsection.to, { replace: false })}}
                                                            disabled={subsection.to === '#'}
                                                        >
                                                            <Typography
                                                                variant="body1"
                                                                component="span"
                                                                fontWeight="normal"
                                                                color="grey.100"
                                                                sx={{ fontSize: '1rem' }}
                                                            >
                                                                {subsection.title}
                                                            </Typography>
                                                        </ListItemButton>
                                                    )
                                                })}
                                            </List>
                                        </Collapse>
                                    </>
                                )
                            })}
                        </List>
                    </Grid>}
                    <Grid
                        container
                        direction={{ xs: 'column', md: 'row' }}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        {!isSmallScreen && <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            item
                            xs="auto"
                            sx={{ pb: 1 }}
                        >
                            <ButtonBase
                                disableRipple
                                component={LinkDOM}
                                to={'/'}
                                sx={{
                                    pr: 1,
                                    borderRight: '1px solid white',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={logoKnockout}
                                    alt="CARE"
                                    sx={{
                                        height: '40px',
                                    }}
                                />
                            </ButtonBase>
                            <Box sx={{ pt: 1, pl: 2 }}>
                                <Link
                                    href="https://www.facebook.com/upcareph"
                                    target="_blank"
                                    rel="noopener"
                                    aria-label="UP Care Facebook Page"
                                >
                                    <FacebookIcon />
                                </Link>
                            </Box>
                        </Grid>}
                        <Grid container direction="row" alignItems="center" justifyContent="flex-end" item xs="auto">
                            <Link
                                href="https://graph.upcare.ph"
                                target="_blank"
                                rel="noopener"
                                aria-label="Air Aware - Data Viewer"
                            >
                                <img src={AirAwareKnockout} alt="Air Aware" height="60px" />
                            </Link>
                        </Grid>
                        {isSmallScreen && <Grid container direction="row" alignItems="center" justifyContent="center" item xs="auto">
                            <Box sx={{ pt: 1, pl: 2, pb: 2 }}>
                                <Link
                                    href="https://www.facebook.com/upcareph"
                                    target="_blank"
                                    rel="noopener"
                                    aria-label="UP Care Facebook Page"
                                >
                                    <FacebookIcon />
                                </Link>
                            </Box>
                        </Grid>}
                    </Grid>
                    <Grid
                        container
                        direction={{ xs: 'column', md: 'row' }}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems={{ xs: 'center', md: 'flex-start' }}
                            sx={{ pl: 1 }}
                            item
                            xs="auto"
                        >
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={{ xs: 'center', md: 'flex-start' }}
                                item
                                sx={{ pb: 1 }}
                            >
                                <ButtonBase
                                    disableRipple
                                    component={LinkDOM}
                                    to={'/privacy-policy'}
                                    sx={{
                                        pr: 1,
                                        borderRight: '1px solid white',
                                    }}
                                >
                                    <Typography component="span" variant="body1" fontWeight="normal" color="grey.100">
                                        Privacy Policy
                                    </Typography>
                                </ButtonBase>
                                <ButtonBase
                                    disableRipple
                                    component={LinkDOM}
                                    to={'/terms-of-use'}
                                    sx={{
                                        pl: 1,
                                    }}
                                >
                                    <Typography component="span" variant="body1" fontWeight="normal" color="grey.100">
                                        Terms of Use
                                    </Typography>
                                </ButtonBase>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={{ xs: 'center', md: 'flex-start' }}
                                item
                                sx={{ pb: 1 }}
                            >
                                <Typography component="span" variant="body1" color="grey.100" textAlign="center">
                                    Copyright © 2024 UP CARE - All Rights Reserved.
                                </Typography>
                            </Grid>
                        </Grid>
                        {!isSmallScreen && <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems={{ xs: 'center', md: 'flex-end' }}
                            sx={{ pr: 1 }}
                            item
                            xs="auto"
                        >
                            <Typography component="span" variant="body1" color="grey.100" textAlign='center'>
                                Room 408, Juinio Hall - National Engineering Center (NEC),
                            </Typography>
                            <Typography component="span" variant="body1" color="grey.100">
                                University of the Philippines, Diliman, Quezon City 1101
                            </Typography>
                        </Grid>}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default Footer;
