// ==============================|| keyEvents MENU ITEMS ||============================== //

const keyEvents = {
    id: 'key-events',
    title: 'Key Events',
    type: 'group',
    children: [
        {
            id: 'air-aware',
            title: 'Air Aware',
            type: 'item',
            url: '/key-events/air-aware',
            breadcrumbs: true,
        },
        // {
        //     id: 'air-aware-diliman-2023',
        //     title: 'Air Aware Diliman 2023',
        //     type: 'item',
        //     url: '/key-events/air-aware-diliman-2023',
        //     breadcrumbs: true,
        // },
    ],
};

export default keyEvents;
