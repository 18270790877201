import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import IntroToAirPollution from 'views/resources/introduction-to-pollution';
import CommonAirPollutants from 'views/resources/common-air-pollutants';
import UnderstandingAirQuality from 'views/resources/understanding-air-quality';
import AirQualityMonitoring from 'views/resources/air-quality-monitoring';
import ImportanceOfAQM from 'views/resources/importance-of-aqm';
import Summary from 'views/resources/summary-and-references';

// // utilities routing
const AirQuality101 = Loadable(lazy(() => import('views/resources/air-quality-101')));
const Publications = Loadable(lazy(() => import('views/sample-page')));
const News = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const ResourcesRoutes = {
    path: '/resources',
    element: <MainLayout />,
    children: [
        {
            path: 'air-quality-101',
            element: <AirQuality101 />,
        },
        {
            path: 'air-quality-101/introduction-to-pollution',
            element: <IntroToAirPollution />,
        },
        {
            path: 'air-quality-101/common-air-pollutants',
            element: <CommonAirPollutants />,
        },
        {
            path: 'air-quality-101/understanding-air-quality',
            element: <UnderstandingAirQuality />,
        },
        {
            path: 'air-quality-101/air-quality-monitoring',
            element: <AirQualityMonitoring />,
        },
        {
            path: 'air-quality-101/importance-of-aqm',
            element: <ImportanceOfAQM />,
        },
        {
            path: 'air-quality-101/summary-and-references',
            element: <Summary />,
        },
        {
            path: 'publications',
            element: <Publications />,
        },
        {
            path: 'news',
            element: <News />,
        },
    ],
};

export default ResourcesRoutes;
