import { Box, Link } from '@mui/material';
import { Helmet } from 'react-helmet';
import Footer from 'ui-component/Footer';

import bg6 from '../../../assets/images/aq101-bg-6.webp';
import { Start, TableOfContents, Content, InfoCard, Scroll } from '../air-quality-101-subpage-theme';
import { airqualityTheme, Congrats, ContactSection } from '../air-quality-101-theme';

const AQ101_Subpage = () => {
    return (
        <>
            <Helmet>
                <title>Summary | UP CARE</title>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    position: 'relative',
                }}
            >
                <Box>
                    {/* Start */}
                    <Start
                        title={'Our last stop for today is the Summary!'}
                        text={'Air is everywhere. It is the water of our lungs—vital to our very existence.'}
                    />
                    {/* TOC for small screens */}
                    <Box display={{ xs: 'flex', md: 'none' }}>
                        <TableOfContents />
                    </Box>
                    {/* Content */}
                    <Content
                        bg={bg6}
                        children={
                            <>
                                <InfoCard
                                    isTopBox={true}
                                    body={
                                        <>
                                            Air pollution affects our health, environment, and economy in major ways.
                                            That's why improving air quality through proper monitoring and management is
                                            key to creating a healthier, more sustainable future. In this guide, we have
                                            explored air pollution, the different types of air quality monitoring
                                            networks, the available technologies, and the impact of poor air quality on
                                            our lives.
                                        </>
                                    }
                                />
                                <InfoCard
                                    variant={0}
                                    title={'References'}
                                    children={
                                        <Box
                                            sx={{
                                                fontSize: { xs: '1rem', md: '1.25rem' },
                                                lineHeight: { xs: '1.5rem', md: '2rem' },
                                                letterSpacing: '0.05rem',
                                            }}
                                        >
                                            <ul>
                                                <li>
                                                    <i>Air pollution and your health. (2024, August 6)</i>. National
                                                    Institute of Environmental Health Sciences. Retrieved from{' '}
                                                    <Link
                                                        href="https://www.niehs.nih.gov/health/topics/agents/air-pollution"
                                                        sx={{
                                                            wordWrap: 'break-word',
                                                            wordBreak: 'break-all',
                                                            overflowWrap: 'break-word',
                                                            color: airqualityTheme.palette.primary.dark,
                                                        }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        https://www.niehs.nih.gov/health/topics/agents/air-pollution
                                                    </Link>
                                                </li>
                                                <li>
                                                    <i>Air quality index (AQI) basics</i>. (n.d.). AirNow. Retrieved
                                                    from{' '}
                                                    <Link
                                                        href="https://www.airnow.gov/aqi/aqi-basics/"
                                                        sx={{
                                                            wordWrap: 'break-word',
                                                            wordBreak: 'break-all',
                                                            overflowWrap: 'break-word',
                                                            color: airqualityTheme.palette.primary.dark,
                                                        }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        https://www.airnow.gov/aqi/aqi-basics/
                                                    </Link>
                                                </li>
                                                <li>
                                                    <i>Air quality research using satellite remote sensing</i>. (n.d.).
                                                    California Air Resources Board. Retrieved from{' '}
                                                    <Link
                                                        href="https://ww2.arb.ca.gov/resources/documents/air-quality-research-using-satellite-remote-sensing"
                                                        sx={{
                                                            wordWrap: 'break-word',
                                                            wordBreak: 'break-all',
                                                            overflowWrap: 'break-word',
                                                            color: airqualityTheme.palette.primary.dark,
                                                        }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        https://ww2.arb.ca.gov/resources/documents/air-quality-research-using-satellite-remote-sensing
                                                    </Link>
                                                </li>
                                                <li>
                                                    <i>Basic information about air emissions monitoring</i>. (2024, July
                                                    30). United States Environmental Protection Agency. Retrieved from{' '}
                                                    <Link
                                                        href="https://www.epa.gov/air-emissions-monitoring-knowledge-base/basic-information-about-air-emissions-monitoring"
                                                        sx={{
                                                            wordWrap: 'break-word',
                                                            wordBreak: 'break-all',
                                                            overflowWrap: 'break-word',
                                                            color: airqualityTheme.palette.primary.dark,
                                                        }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        https://www.epa.gov/air-emissions-monitoring-knowledge-base/basic-information-about-air-emissions-monitoring
                                                    </Link>
                                                </li>
                                                <li>
                                                    <i>Common air pollutants and their health effects</i>. (2022, July
                                                    8). NSW Health. Retrieved from{' '}
                                                    <Link
                                                        href="https://www.health.nsw.gov.au/environment/air/Pages/common-air-pollutants.aspx"
                                                        sx={{
                                                            wordWrap: 'break-word',
                                                            wordBreak: 'break-all',
                                                            overflowWrap: 'break-word',
                                                            color: airqualityTheme.palette.primary.dark,
                                                        }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        https://www.health.nsw.gov.au/environment/air/Pages/common-air-pollutants.aspx
                                                    </Link>
                                                </li>
                                                <li>
                                                    <i>Low-cost air pollution monitors and indoor air quality</i>.
                                                    (2023, January 3). United States Environmental Protection Agency.
                                                    Retrieved from{' '}
                                                    <Link
                                                        href="https://www.epa.gov/indoor-air-quality-iaq/low-cost-air-pollution-monitors-and-indoor-air-quality"
                                                        sx={{
                                                            wordWrap: 'break-word',
                                                            wordBreak: 'break-all',
                                                            overflowWrap: 'break-word',
                                                            color: airqualityTheme.palette.primary.dark,
                                                        }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        https://www.epa.gov/indoor-air-quality-iaq/low-cost-air-pollution-monitors-and-indoor-air-quality
                                                    </Link>
                                                </li>
                                                <li>
                                                    Mackenzie, J., & Turrentine, J. (2023, October 31).{' '}
                                                    <i>Air pollution: Everything you need to know</i>. Natural Resources
                                                    Defense Council. Retrieved from{' '}
                                                    <Link
                                                        href="https://www.nrdc.org/stories/air-pollution-everything-you-need-know#whatis"
                                                        sx={{
                                                            wordWrap: 'break-word',
                                                            wordBreak: 'break-all',
                                                            overflowWrap: 'break-word',
                                                            color: airqualityTheme.palette.primary.dark,
                                                        }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        https://www.nrdc.org/stories/air-pollution-everything-you-need-know#whatis
                                                    </Link>
                                                </li>
                                                <li>
                                                    <i>Mobile monitoring research studies</i>. (n.d.). California Air
                                                    Resources Board. Retrieved from{' '}
                                                    <Link
                                                        href="https://ww2.arb.ca.gov/resources/documents/mobile-monitoring-research-studies"
                                                        sx={{
                                                            wordWrap: 'break-word',
                                                            wordBreak: 'break-all',
                                                            overflowWrap: 'break-word',
                                                            color: airqualityTheme.palette.primary.dark,
                                                        }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        https://ww2.arb.ca.gov/resources/documents/mobile-monitoring-research-studies
                                                    </Link>
                                                </li>
                                                <li>
                                                    <i>What is air quality?</i> (n.d.). University Corporation for
                                                    Atmospheric Research. Retrieved from{' '}
                                                    <Link
                                                        href="https://scied.ucar.edu/learning-zone/air-quality/what-is-air-quality"
                                                        sx={{
                                                            wordWrap: 'break-word',
                                                            wordBreak: 'break-all',
                                                            overflowWrap: 'break-word',
                                                            color: airqualityTheme.palette.primary.dark,
                                                        }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        https://scied.ucar.edu/learning-zone/air-quality/what-is-air-quality
                                                    </Link>
                                                </li>
                                            </ul>
                                        </Box>
                                    }
                                />
                            </>
                        }
                    />
                    <Scroll />
                </Box>
                {/* TOC for large screens */}
                <Box display={{ xs: 'none', md: 'flex' }}>
                    <TableOfContents />
                </Box>
            </Box>
            {/* Congrats */}
            <Box marginTop={{ xs: '-4rem', md: '-8rem' }}>
                <Congrats />
            </Box>
            <ContactSection text="EXPLORE OUR PROJECTS>" link="/about-us/projects" />
            <Footer />
        </>
    );
};

export default AQ101_Subpage;
