import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Helmet } from 'react-helmet';
import Footer from 'ui-component/Footer';

import bg3 from '../../../assets/images/aq101-bg-3.webp';
import { Start, TableOfContents, Content, InfoCard, Scroll } from '../air-quality-101-subpage-theme';
import { airqualityTheme } from '../air-quality-101-theme';

const AQ101_Subpage = () => {
    const AQITable = () => {
        // Data for the AQI table
        const aqiData = [
            {
                aqi: '0-50',
                level: 'Good',
                color: 'Green',
                colortext: 'Green',
                description: 'Air quality is considered satisfactory, and air pollution poses little or no risk.',
            },
            {
                aqi: '51-100',
                level: 'Moderate',
                color: 'Yellow',
                colortext: 'Yellow',
                description:
                    'Air quality is acceptable. However, there may be a risk for some people, particularly those who are unusually sensitive to air pollution.',
            },
            {
                aqi: '101-150',
                level: 'Unhealthy for Sensitive Groups',
                color: '#ff7e00', // orange
                colortext: 'Orange',
                description:
                    'Members of sensitive groups may experience health effects. The general public is less likely to be affected.',
            },
            {
                aqi: '151-200',
                level: 'Unhealthy',
                color: 'Red',
                colortext: 'Red',
                description:
                    'Some members of the general public may experience health effects; members of sensitive groups may experience more serious health effects.',
            },
            {
                aqi: '201-300',
                level: 'Very Unhealthy',
                color: 'Purple',
                colortext: 'Purple',
                description: 'Health alert: The risk of health effects is increased for everyone.',
            },
            {
                aqi: '301 and higher',
                level: 'Hazardous',
                color: 'Maroon',
                colortext: 'Maroon',
                description: 'Health warning of emergency conditions: everyone is more likely to be affected.',
            },
        ];

        return (
            <TableContainer component={Paper}>
                <Table aria-label="AQI table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 700, color: '#000' }}>Color</TableCell>
                            <TableCell sx={{ fontWeight: 700, color: '#000' }}>Level of Concern</TableCell>
                            <TableCell sx={{ fontWeight: 700, color: '#000' }}>AQI Range</TableCell>
                            <TableCell
                                sx={{ fontWeight: 700, color: '#000', display: { xs: 'none', lg: 'table-cell' } }}
                            >
                                Description of Air Quality
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {aqiData.map((row) => (
                            <>
                                <TableRow key={row.aqi}>
                                    {/* Color Cell */}
                                    <TableCell
                                        sx={{
                                            color: '#000',
                                            whiteSpace: { lg: 'nowrap' },
                                            textAlign: { xs: 'center', sm: 'left', md: 'center', lg: 'left' },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '1rem',
                                                height: '1rem',
                                                backgroundColor: row.color.toLowerCase(),
                                                display: 'inline-block',
                                                borderRadius: '50%',
                                            }}
                                        />
                                        &nbsp;&nbsp;{row.colortext}
                                    </TableCell>

                                    {/* Level of Concern Cell */}
                                    <TableCell sx={{ color: '#000' }}>{row.level}</TableCell>

                                    {/* AQI Range Cell */}
                                    <TableCell sx={{ color: '#000' }}>{row.aqi}</TableCell>

                                    {/* Description Cell (conditionally rendered, large screens) */}
                                    <TableCell sx={{ color: '#000', display: { xs: 'none', lg: 'table-cell' } }}>
                                        {row.description}
                                    </TableCell>
                                </TableRow>

                                {/* Description Cell (conditionally rendered, small screens) */}
                                <TableRow sx={{ display: { lg: 'none' } }}>
                                    <TableCell colSpan={3} color="#000">
                                        <b>Description: </b>
                                        {row.description}
                                    </TableCell>
                                </TableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <>
            <Helmet>
                <title>Understanding Air Quality | UP CARE</title>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    position: 'relative',
                }}
            >
                <Box>
                    {/* Start */}
                    <Start
                        title={"Great! Now let's move on to Understanding Air Quality."}
                        text={
                            'Air quality refers to the cleanliness of the air we breathe and the absence or presence of harmful pollutants.'
                        }
                    />
                    {/* TOC for small screens */}
                    <Box display={{ xs: 'flex', md: 'none' }}>
                        <TableOfContents />
                    </Box>
                    {/* Content */}
                    <Content
                        bg={bg3}
                        children={
                            <>
                                <InfoCard
                                    isTopBox={true}
                                    title={'Air Quality Index (AQI)'}
                                    body={
                                        <>
                                            <br />• AQI describes air quality based on the concentration of pollutants
                                            present in the air at a particular location.
                                            <br />
                                            <br />• The AQI is divided into six categories, with values ranging from
                                            0-500, each having a specific color, corresponding to different levels of
                                            health concern.
                                        </>
                                    }
                                />
                                <InfoCard
                                    variant={0}
                                    title={'AQI Basics for Ozone and Particle Pollution'}
                                    body={
                                        <>
                                            <br />
                                            <i>Source: (AirNow) Air Quality Index (AQI) Basics</i>
                                            <br />
                                            <br />
                                        </>
                                    }
                                    children={<AQITable />}
                                />
                                <InfoCard
                                    variant={2}
                                    body={
                                        <>
                                            💡 Our{' '}
                                            <a
                                                href="https://graph.upcare.ph"
                                                style={{
                                                    textDecoration: 'underline',
                                                    color: airqualityTheme.palette.primary.contrastText,
                                                }}
                                            >
                                                Dashboard
                                            </a>{' '}
                                            uses AQI for easy-to-understand monitoring!
                                        </>
                                    }
                                />
                            </>
                        }
                    />
                    <Scroll />
                </Box>
                {/* TOC for large screens */}
                <Box display={{ xs: 'none', md: 'flex' }}>
                    <TableOfContents />
                </Box>
            </Box>
            <Footer />
        </>
    );
};

export default AQ101_Subpage;
