import DOST from '../assets/images/DOST.webp';

import UP from '../assets/images/UP.webp';
import UPDEngg from '../assets/images/UPD-ENGG.webp';
import EEEI from '../assets/images/EEEI.webp';
import ME from '../assets/images/ME.webp';
import ChemEngg from '../assets/images/ChemEngg.webp';
import IESM from '../assets/images/IESM.webp';
import IC from '../assets/images/IC.webp';

import DENR_EMB from '../assets/images/DENR_EMB.webp';
// import PASIG_CENRO from '../assets/images/PASIG_CENRO.webp';
import PCAI from '../assets/images/PCAI.webp';
import DOST_ASTI from '../assets/images/DOST_ASTI.webp';
import airmove from '../assets/images/airmove.webp';
import openaq from '../assets/images/openaq.webp';
import LAECESS from '../assets/images/LAECESS.webp';

import DOLE_OSHC from '../assets/images/DOLE_OSHC.webp';
// import DENR_EMB_NCR from '../assets/images/DENR_EMB_NCR.webp';
import PASIG_LGU from '../assets/images/PASIG_LGU.webp';
import QC_LGU from '../assets/images/QC_LGU.webp';

export const ImplementingAgencies = [
    {
        name: 'University of the Philippines',
        logo: UP,
        alt: 'UP',
        link: 'https://up.edu.ph/',
    },
    {
        name: 'University of the Philippines Diliman\n College of Engineering',
        logo: UPDEngg,
        alt: 'UPD-ENGG',
        link: 'https://www.coe.upd.edu.ph/',
    },
    {
        name: 'University of the Philippines Diliman\n Electrical and Electronics Engineering Institute',
        logo: EEEI,
        alt: 'EEEI',
        link: 'https://eee.upd.edu.ph/',
    },
    {
        name: 'University of the Philippines Diliman\n Department of Mechanical Engineering',
        logo: ME,
        alt: 'Mech Engg',
        link: 'https://mech.engg.upd.edu.ph/',
    },
    {
        name: 'University of the Philippines Diliman\n Department of Chemical Engineering',
        logo: ChemEngg,
        alt: 'Chem Engg',
        link: 'https://dche.coe.upd.edu.ph/',
    },
    {
        name: 'University of the Philippines Diliman\n Institute of Environmental Science and Meteorology',
        logo: IESM,
        alt: 'IESM',
        link: 'https://iesm.science.upd.edu.ph/',
    },
    {
        name: 'University of the Philippines Diliman\n Institute of Chemistry',
        logo: IC,
        alt: 'IC',
        link: 'https://chem.science.upd.edu.ph/',
    },
];

export const ImpactPartners = [
    {
        name: 'Department of Science and Technology\n Advanced Science and Technology Institute',
        logo: DOST_ASTI,
        alt: 'DOST ASTI',
        link: 'https://asti.dost.gov.ph/',
    },
    {
        name: 'AiRMoVE',
        logo: airmove,
        alt: 'AiRMoVE',
        link: '#',
    },
    {
        name: 'OpenAQ',
        logo: openaq,
        alt: 'OpenAQ',
        link: 'https://openaq.org/',
    },
    {
        name: 'Latin America Early Career Earth System Scientist Network',
        logo: LAECESS,
        alt: 'LAECESS',
        link: '#',
    },

    // {
    //     name: 'Pasig City Environment and Natural Resources Office',
    //     logo: PASIG_CENRO,
    //     alt: 'Pasig CENRO',
    //     link: 'https://www.facebook.com/pasigcitycenro/',
    // },
    // {
    //     name: 'Partnership for Clean Air, Inc.',
    //     logo: PCAI,
    //     alt: 'PCAI',
    //     link: '#',
    // },
];

export const Funders = [
    {
        name: 'Department of Science and Technology',
        logo: DOST,
        alt: 'DOST',
        link: 'https://www.dost.gov.ph/',
    },
];

export const TechnicalWorkingGroup = [
    {
        name: 'Department of Environment and Natural Resources\n Environmental Management Bureau',
        logo: DENR_EMB,
        alt: 'EMB',
        link: 'https://emb.gov.ph/',
    },
    {
        name: 'Department of Labor and Employment\n Occupational Safety and Health Center',
        logo: DOLE_OSHC,
        alt: 'DOLE OSHC',
        link: 'https://oshc.dole.gov.ph/',
    },
    {
        name: 'Pasig City\n Local Government Unit',
        logo: PASIG_LGU,
        alt: 'Pasig LGU',
        link: 'https://www.pasigcity.gov.ph/',
    },
    {
        name: 'Quezon City\n Local Government Unit',
        logo: QC_LGU,
        alt: 'QC LGU',
        link: 'https://quezoncity.gov.ph/',
    },
    {
        name: 'Partnership for Clean Air, Inc.',
        logo: PCAI,
        alt: 'PCAI',
        link: '#',
    },

    // {
    //     name: 'Department of Environment and Natural Resources\n Environmental Management Bureau\n National Capital Region',
    //     logo: DENR_EMB_NCR,
    //     alt: 'DENR EMB NCR',
    //     link: 'https://ncr.emb.gov.ph/',
    // },
];

export const PagesList = [
    {
        name: 'Home',
        path: '/',
        type: 'link',
    },
    {
        name: 'Be Involved',
        path: '#',
        type: 'menu',
        children: [
            {
                name: 'Air Aware Network',
                path: '/be-involved/air-aware-network',
                type: 'link',
            },
            {
                name: 'Air Aware Communities',
                path: '/be-involved/air-aware-communities',
                type: 'link',
            },
            {
                name: 'Air Aware Conference',
                path: '/be-involved/air-aware-conference',
                type: 'link',
                disabled: true,
            },
        ],
    },
    {
        name: 'About Us',
        path: '#',
        type: 'menu',
        children: [
            {
                name: 'Our DNA',
                path: '/about-us/our-dna',
                type: 'link',
            },
            {
                name: 'People',
                path: '/about-us/people',
                type: 'link',
            },
            {
                name: 'Projects',
                path: '/about-us/projects',
                type: 'link',
            },
        ],
    },
    {
        name: 'Solutions',
        path: '#',
        type: 'menu',
        children: [
            {
                name: 'For Government',
                path: '#',
                type: 'link',
                disabled: true,
            },
            {
                name: 'For Researchers',
                path: '#',
                type: 'link',
                disabled: true,
            },
            {
                name: 'For The Public',
                path: '#',
                type: 'link',
                disabled: true,
            },
        ],
    },
    {
        name: 'Resources',
        path: '#',
        type: 'menu',
        children: [
            {
                name: 'Air Quality 101',
                path: '/resources/air-quality-101',
                type: 'link',
            },
            {
                name: 'Publications',
                path: '#',
                type: 'link',
                disabled: true,
            },
            {
                name: 'News',
                path: '#',
                type: 'link',
                disabled: true,
            },
        ],
    },
    {
        name: 'Contact Us',
        path: '/contact-us',
        type: 'link',
    },
];

export const FooterSections = [
    {
        section: 'Be Involved',
        subsections: [
            {
                title: 'Air Aware Network',
                to: '/be-involved/air-aware-network',
            },
            {
                title: 'Air Aware Communities',
                to: '/be-involved/air-aware-communities',
            },
            {
                title: 'Air Aware Conference',
                to: '#',
            },
        ],
    },
    {
        section: 'About Us',
        subsections: [
            {
                title: 'Our DNA',
                to: '/about-us/our-dna',
            },
            {
                title: 'People',
                to: '/about-us/people',
            },
            {
                title: 'Projects',
                to: '/about-us/projects',
            },
        ],
    },
    {
        section: 'Solutions',
        subsections: [
            {
                title: 'For Government',
                to: '#',
            },
            {
                title: 'For Researchers',
                to: '#',
            },
            {
                title: 'For The Public',
                to: '#',
            },
        ],
    },
    {
        section: 'Resources',
        subsections: [
            {
                title: 'Air Quality 101',
                to: '/resources/air-quality-101',
            },
            {
                title: 'Publications',
                to: '#',
            },
            {
                title: 'News',
                to: '#',
            },
        ],
    },
];
