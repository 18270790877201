import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// // utilities routing
const PrivacyPolicy = Loadable(lazy(() => import('views/privacy-policy')));
const TermsOfUse = Loadable(lazy(() => import('views/terms-of-use')));

const OtherRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'privacy-policy',
            element: <PrivacyPolicy />,
        },
        {
            path: 'terms-of-use',
            element: <TermsOfUse />,
        }
    ],
};

export default OtherRoutes;
