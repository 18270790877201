import { Box, Divider } from '@mui/material';
import { Helmet } from 'react-helmet';
import Footer from 'ui-component/Footer';

import bg4 from '../../../assets/images/aq101-bg-4.webp';
import { Start, TableOfContents, Content, InfoCard, Scroll } from '../air-quality-101-subpage-theme';

const AQ101_Subpage = () => {
    return (
        <>
            <Helmet>
                <title>Air Quality Monitoring (AQM) | UP CARE</title>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    position: 'relative',
                }}
            >
                <Box>
                    {/* Start */}
                    <Start
                        title={'Onto the exciting part! Air Quality Monitoring (AQM).'}
                        text={
                            'Used to monitor key pollutants: Particulate Matter (PM2.5, PM10), Nitrogen Dioxide (NO2), Sulfur Dioxide (SO2), Ozone (O3), Carbon Monoxide (CO), and Volatile Organic Compounds (VOCs).'
                        }
                    />
                    {/* TOC for small screens */}
                    <Box display={{ xs: 'flex', md: 'none' }}>
                        <TableOfContents />
                    </Box>
                    {/* Content */}
                    <Content
                        bg={bg4}
                        children={
                            <>
                                <InfoCard
                                    isTopBox={true}
                                    body={
                                        <>
                                            <b>Air quality monitoring (AQM)</b> is the collection and measurement of
                                            ambient air pollution samples to assess air quality, track changes over
                                            time, and inform the public. The data from these samples is compared to
                                            clean air standards and historical information regarding air quality levels,
                                            along with data reflecting its health and environmental impacts, to
                                            determine the state of the air. It also allows officials to determine
                                            compliance with certain air quality standards.
                                        </>
                                    }
                                />
                                <InfoCard
                                    variant={0}
                                    title={'Types of Monitoring Stations'}
                                    body={
                                        <>
                                            <br />• <b>Low-cost sensors (aka small sensors)</b>
                                            <br />
                                            <br />
                                            These are devices that are designed to detect levels of certain pollutants,
                                            such as particulate matter and nitrogen dioxide, as well as certain
                                            environmental factors like temperature and humidity. Because these are
                                            affordable, they can be used by individuals or communities for localized
                                            monitoring or be deployed at a high density to capture more high-resolution
                                            data.
                                            <br />
                                            <br />
                                            <Divider
                                                sx={{
                                                    border: '0.5px solid #999',
                                                    margin: { xs: '20px 50px', lg: '20px 100px' },
                                                }}
                                            />
                                            <br />• <b>Fixed/stationary monitoring stations </b>
                                            <br />
                                            <br />
                                            These are permanent stations in key areas (urban centers, industrial zones,
                                            or in a community that experiences high air pollution) that provide
                                            continuous data.
                                            <br />
                                            <br />
                                            <Divider
                                                sx={{
                                                    border: '0.5px solid #999',
                                                    margin: { xs: '20px 50px', lg: '20px 100px' },
                                                }}
                                            />
                                            <br />• <b>Mobile monitoring stations</b>
                                            <br />
                                            <br />
                                            These use vehicles or drones to gather air quality data across large areas
                                            quickly, though they may miss some pollution trends or hotspots. Equipment
                                            used can be linked to GPS systems to geolocate air pollutants and track
                                            changes over time.
                                            <br />
                                            <br />
                                            <Divider
                                                sx={{
                                                    border: '0.5px solid #999',
                                                    margin: { xs: '20px 50px', lg: '20px 100px' },
                                                }}
                                            />
                                            <br />• <b>Satellite-based monitoring stations</b>
                                            <br />
                                            <br />
                                            These stations track pollution events and trends on a much greater
                                            geographical coverage than other monitoring technologies. It complements
                                            ground-based data by covering remote regions and regions without
                                            infrastructure or sparse monitoring networks.
                                        </>
                                    }
                                />
                                <InfoCard
                                    variant={2}
                                    body={'💡 In CARE, we use small sensors and, in the future, mobile monitoring!'}
                                />
                            </>
                        }
                    />{' '}
                    <Scroll />
                </Box>
                {/* TOC for large screens */}
                <Box display={{ xs: 'none', md: 'flex' }}>
                    <TableOfContents />
                </Box>
            </Box>
            <Footer />
        </>
    );
};

export default AQ101_Subpage;
