import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button, MenuItem, Typography } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import HoverMenu from 'material-ui-popup-state/HoverMenu';
import PopupState, { bindHover, bindMenu } from 'material-ui-popup-state';

const HoverMenuButton = ({ title, menuItems }) => {
    const navigate = useNavigate();

    return (
        <>
            <PopupState variant="popover" popupId="demoMenu">
                {(popupState) => (
                    <>
                        <Button
                            variant="text"
                            endIcon={
                                popupState.isOpen ? (
                                    <IconChevronUp
                                        stroke={1.5}
                                        size="1rem"
                                        style={{ marginTop: 'auto', marginBottom: 'auto' }}
                                    />
                                ) : (
                                    <IconChevronDown
                                        stroke={1.5}
                                        size="1rem"
                                        style={{ marginTop: 'auto', marginBottom: 'auto' }}
                                    />
                                )
                            }
                            sx={{
                                borderRadius: '0px',
                                alignItems: 'flex-start',
                                backgroundColor: 'inherit',
                                color: 'grey.100',
                                pb: 0.25,
                                px: 0,
                                mx: 1,
                                '&:hover': {
                                    borderBottom: '2px solid',
                                },

                                elevation: 0,
                            }}
                            {...bindHover(popupState)}
                        >
                            <Typography color="inherit">{title}</Typography>
                        </Button>
                        <HoverMenu
                            {...bindMenu(popupState)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            
                        >
                            {menuItems.map((item, i) => {
                                return (
                                    <MenuItem
                                        key={i}
                                        onClick={() => {
                                            if (item.type === 'external') {
                                                window.open(item.path, '_blank'); // Open the link in a new window/tab
                                            } else {
                                                navigate(item.path, { replace: false });
                                            }
                                        }}
                                        disabled={item.disabled}
                                    >
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                        </HoverMenu>
                    </>
                )}
            </PopupState>
        </>
    );

    // const navigate = useNavigate();
    // const customization = useSelector((state) => state.customization);

    // const [anchorEl, setAnchorEl] = useState(null);
    // const open = Boolean(anchorEl);

    // const handleMouseEnter = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handleMouseLeave = () => {
    //     setAnchorEl(null);
    // };

    // return (
    //     // <div
    //     // // onMouseLeave={handleMouseLeave}
    //     // >
    //     <>
    //         <Button
    //             // onMouseOver={handleMouseEnter}
    //             onClick={handleMouseEnter}
    //             endIcon={open ? (
    //                 <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    //             ) : (
    //                 <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    //             )}
    //             sx={{
    //                 borderRadius: `${customization.borderRadius}px`,
    //                 alignItems: 'flex-start',
    //                 backgroundColor: 'inherit',
    //                 '&:hover': {
    //                     backgroundColor: 'secondary.light',
    //                 },
    //                 color: 'inherit',
    //             }}
    //         >
    //             <Typography color="inherit">{title}</Typography>
    //         </Button>
    //         <Menu
    //             anchorEl={anchorEl}
    //             open={open}
    //             onClose={handleMouseLeave}
    //             MenuListProps={{
    //                 'aria-labelledby': 'basic-button',
    //             }}
    //         >
    //             {menuItems.map((item, i) => {
    //                 return (
    //                     <MenuItem
    //                         key={i}
    //                         onClick={() => {
    //                             if (item.type === 'external') {
    //                                 window.open(item.path, '_blank'); // Open the link in a new window/tab
    //                             } else {
    //                                 navigate(item.path, { replace: false });
    //                             }
    //                         }}
    //                     >
    //                         {item.name}
    //                     </MenuItem>
    //                 );
    //             })}
    //         </Menu>
    //     </>
    // </div>
    // );
};

HoverMenuButton.propTypes = {
    title: PropTypes.string.isRequired,
    menuItems: PropTypes.array.isRequired,
};

export default HoverMenuButton;
