// ==============================|| about MENU ITEMS ||============================== //

const aboutUs = {
    id: 'about-us',
    title: 'About Us',
    type: 'group',
    children: [
        {
            id: 'our-story',
            title: 'Our Story',
            type: 'item',
            url: '/about-us/our-story',
            breadcrumbs: false,
        },
        {
            id: 'people',
            title: 'People',
            type: 'item',
            url: '/about-us/people',
            breadcrumbs: false,
        },
        {
            id: 'projects',
            title: 'Projects',
            type: 'item',
            url: '/about-us/projects',
            breadcrumbs: false,
        },
        {
            id: 'project1',
            title: 'Project 1',
            type: 'item',
            url: '/about-us/projects/1',
            breadcrumbs: false,
        },
        {
            id: 'project4',
            title: 'Project 4',
            type: 'item',
            url: '/about-us/projects/4',
            breadcrumbs: false,
        },
        {
            id: 'project5',
            title: 'Project 5',
            type: 'item',
            url: '/about-us/projects/5',
            breadcrumbs: false,
        },
    ],
};

export default aboutUs;
