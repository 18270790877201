// ==============================|| about MENU ITEMS ||============================== //

const about = {
    id: 'about',
    title: 'About',
    type: 'group',
    children: [
        {
            id: 'who-we-are',
            title: 'Who We Are',
            type: 'item',
            url: '/about/who-we-are',
            breadcrumbs: true,
        },
        {
            id: 'project1',
            title: 'Project 1',
            type: 'item',
            url: '/about/project-1',
            breadcrumbs: true,
        },
        {
            id : 'project4',
            title: 'Project 4',
            type: 'item',
            url: '/about/project-4',
            breadcrumbs: true,
        },
        {
            id: 'project5',
            title: 'Project 5',
            type: 'item',
            url: '/about/project-5',
            breadcrumbs: true,
        },
        {
            id: 'what-we-offer',
            title: 'What We Offer',
            type: 'item',
            url: '/about/what-we-offer',
            breadcrumbs: true,
        },
        {
            id: 'people',
            title: 'People',
            type: 'item',
            url: '/about-us/people',
            breadcrumbs: true,
        },
        {
            id: 'partners',
            title: 'Partners',
            type: 'item',
            url: '/about/partners',
            breadcrumbs: true,
        },
        {
            id: 'projects',
            title: 'Projects',
            type: 'item',
            url: '/about-us/projects',
            breadcrumbs: true,
        },
    ],
};

export default about;
