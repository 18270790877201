// ==============================|| be involved MENU ITEMS ||============================== //

const beInvolved = {
    id: 'be-involved',
    title: 'Be Involved',
    type: 'group',
    children: [
        {
            id: 'air-aware-network',
            title: 'Air Aware Network',
            type: 'item',
            url: '/be-involved/air-aware-network',
            breadcrumbs: false,
        },
        {
            id: 'air-aware-communities',
            title: 'Air Aware Communities',
            type: 'item',
            url: '/be-involved/air-aware-communities',
            breadcrumbs: false,
        },
        {
            id: 'air-aware-conference',
            title: 'Air Aware Conference',
            type: 'item',
            url: '/be-involved/air-aware-conference',
            breadcrumbs: true,
        },
    ],
};

export default beInvolved;
