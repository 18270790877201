import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';


// // utilities routing
const AirAware = Loadable(lazy(() => import('views/key-events/air-aware/index')));
const AirAwareDiliman2023 = Loadable(lazy(() => import('views/key-events/AirAwareDiliman2023')));

// ==============================|| MAIN ROUTING ||============================== //

const KeyEventsRoutes = {
    path: '/key-events',
    element: <MainLayout />,
    children: [
        {
            path: 'air-aware',
            element: <AirAware />,
        },
        {
            path: 'air-aware-diliman-2023',
            element: <AirAwareDiliman2023 />,
        },
    ],
};

export default KeyEventsRoutes;
