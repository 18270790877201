import React from 'react';
import { Helmet } from 'react-helmet';

import { Box, ThemeProvider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link as Element } from 'react-scroll';

import Footer from 'ui-component/Footer';

import DataVisualization from '../../../assets/images/DataVisualization.webp';
import Icon_Project1 from '../../../assets/images/Icon_Project1.webp';
import DotsAccent2 from '../../../assets/images/DotsAccent2.webp';
import SensorNodeDeployed5 from '../../../assets/images/SensorNodeDeployed5.webp';
import SensorNodeDeployed4 from '../../../assets/images/SensorNodeDeployed4.webp';
import eTraffic3 from '../../../assets/images/eTraffic3.webp';
import Grafana_UPCARE3 from '../../../assets/images/Grafana_UPCARE3.webp';

import For_Government from '../../../assets/images/For_Government.webp';
import For_Public from '../../../assets/images/For_Public.webp';
import For_Researchers from '../../../assets/images/For_Researchers.webp';

import { marginMobile, marginDesktop, projectTheme, InfoCard, marginDesktopBetween } from './projectTheme';
import { SolutionsSection, ContactSection, StartSection, ImportanceSection } from './projectTheme';

const People = () => {
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
    // const isMobileScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const benefits = [
        {
            imageAlt: 'Government',
            description: 'Policy-making, decision-making, crafting and assessment of air quality programs, etc.',
            image: For_Government,
        },
        {
            imageAlt: 'Researchers',
            description:
                'Better understanding of air pollution via data analytics, modeling applications, and further studies',
            image: For_Researchers,
        },
        {
            imageAlt: 'Public',
            description:
                'Personal exposure data, street-level air pollution maps, household indoor air quality monitoring, etc.',
            image: For_Public,
        },
    ];

    const infoCards = [
        {
            src: SensorNodeDeployed4,
            alt: 'CARE Node',
            body: (
                <>
                    Project 1 focuses on the design and {isMediumScreen && <br />}
                    deployment of small air pollution sensor {isMediumScreen && <br />}
                    nodes to measure air pollutants, aerosols, {isMediumScreen && <br />}
                    particulate matter, and gases.
                </>
            ),
        },
        {
            src: eTraffic3,
            alt: 'eTraffic',
            flexDir: 'row-reverse',
            body: (
                <>
                    An eTraffic video capture system is also {isMediumScreen && <br />}
                    being developed to provide information on {isMediumScreen && <br />}
                    traffic such as average traffic speed, traffic {isMediumScreen && <br />}
                    volume, and types of vehicles on the road. {isMediumScreen && <br />}
                    These data will be valuable in further {isMediumScreen && <br />}
                    understanding the mobile sources of air {isMediumScreen && <br />}
                    pollution by correlating eTraffic and {isMediumScreen && <br />}
                    sensor node data.
                </>
            ),
        },
        {
            src: Grafana_UPCARE3,
            alt: 'Dashboard',
            body: (
                <>
                    Data collected may be used by various {isMediumScreen && <br />}
                    stakeholders such as LGUs, NGOs, {isMediumScreen && <br />}
                    government agencies, and the public using {isMediumScreen && <br />}
                    web or mobile applications and standard {isMediumScreen && <br />}
                    Internet-of-Things (IoT) protocols.
                </>
            ),
        },
    ];

    return (
        <>
            <Helmet>
                <title>Project 1 | UP CARE</title>
            </Helmet>
            <ThemeProvider theme={projectTheme}>
                <div style={{ overflowX: 'hidden' }}>
                    <Element name="start">
                        <StartSection
                            backdrop={DataVisualization}
                            icon={Icon_Project1}
                            projnum={1}
                            title={
                                <>
                                    Towards Green Smart Cities: <br />
                                    Connected Embedded Systems for Indoor and Outdoor Air Quality
                                </>
                            }
                            lead="John Richard Hizon, PhD"
                            overview={
                                <>
                                    Making the invisible visible {isMediumScreen && <br />}
                                    through cutting-edge sensor network {isMediumScreen && <br />}
                                    systems to monitor and enhance {isMediumScreen && <br />}
                                    indoor/outdoor air quality {isMediumScreen && <br />}
                                    in urban areas
                                </>
                            }
                            tags={['On-Going', 'TRL 5', 'IP Protection In-Progress']}
                        />
                    </Element>
                    <Element name="problem">
                        <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            flexDirection={{ xs: 'column', md: 'row-reverse' }}
                            marginX={{ xs: marginMobile, lg: marginDesktop }}
                        >
                            {/* RIGHT problem */}
                            <Box
                                display={'flex'}
                                marginY={{ xs: marginMobile, md: marginDesktopBetween }}
                                maxWidth={{ md: '73ch', lg: 'none' }}
                                minWidth={{ lg: '73ch' }}
                                justifyContent={'center'}
                            >
                                <InfoCard
                                    title="What Problem Are We Working On?"
                                    body={
                                        <>
                                            Lack of air pollution data in the Philippines.
                                            <br />
                                            <br />
                                            Government and regulatory agencies have {isDesktopScreen && <br />}
                                            relied on using fixed site air quality monitoring stations (AQMs).{' '}
                                            {isDesktopScreen && <br />}
                                            These stations are expensive, require significant operation{' '}
                                            {isDesktopScreen && <br />}
                                            requirements, and use very sophisticated instruments{' '}
                                            {isDesktopScreen && <br />}
                                            thereby resulting in a sparsely distributed network of AQMs.
                                        </>
                                    }
                                />
                            </Box>
                            {/* LEFT node + fact */}
                            <Box
                                position={'relative'}
                                display={'flex'}
                                alignItems={'flex-end'}
                                marginLeft={-5}
                                // maxHeight="45vh"
                            >
                                <Box
                                    component="img"
                                    src={SensorNodeDeployed5}
                                    alt="CARE Node"
                                    sx={{
                                        // maxHeight: '45vh',
                                        height: { xs: '40ch', md: '55ch' },
                                        objectFit: 'cover',
                                    }}
                                    loading="lazy"
                                />
                                <Box
                                    minWidth={{ xs: '20ch', md: '30ch' }}
                                    marginLeft={-1}
                                    marginRight={1}
                                    marginBottom={{ xs: marginMobile, md: marginDesktopBetween }}
                                >
                                    <Typography
                                        fontSize={{ xs: '1rem', md: '1.5' }}
                                        fontStyle="italic"
                                        fontWeight={600}
                                        lineHeight="26px"
                                        color="#52584D"
                                    >
                                        Only 23% of the PH population <br /> is within 5km of an air quality <br />{' '}
                                        monitoring device.
                                    </Typography>
                                    <Typography
                                        fontSize={'0.85rem'}
                                        letterSpacing={0.5}
                                        fontStyle="italic"
                                        fontWeight={300}
                                        lineHeight="20px"
                                        color="#52584D"
                                    >
                                        Source: Center for Research on Energy and Clean Air (2021)
                                    </Typography>
                                </Box>
                                <Box
                                    component="img"
                                    src={DotsAccent2}
                                    sx={{
                                        width: { xs: '100%', sm: '80%', lg: '80%' },
                                        opacity: '0.3',
                                        top: { xs: '-5%', md: '5%' },
                                        right: { xs: '-40%', sm: '-20%', md: '-5%', lg: '-15%' },
                                    }}
                                    position="absolute"
                                    loading="lazy"
                                />
                            </Box>
                        </Box>
                    </Element>
                    <Element name="importance">
                        <ImportanceSection
                            benefits={benefits}
                            body={
                                <>
                                    Air pollution has a direct impact on human {isDesktopScreen && <br />}
                                    health. Without data, we cannot make {isDesktopScreen && <br />}
                                    informed decisions and appropriate actions {isDesktopScreen && <br />}
                                    to address poor air quality.
                                    <br />
                                    <br />
                                    We need to generate more air pollution data {isDesktopScreen && <br />}
                                    to develop more accurate air pollution {isDesktopScreen && <br />}
                                    concentration maps, develop models to {isDesktopScreen && <br />}
                                    forecast and identify the time and areas {isDesktopScreen && <br />}
                                    where levels of air pollution are worst, and {isDesktopScreen && <br />}
                                    help craft and assess the effectiveness of {isDesktopScreen && <br />}
                                    air pollution mitigation programs {isDesktopScreen && <br />}
                                    in the country.
                                </>
                            }
                        />
                    </Element>
                    <Element name="solutions">
                        <SolutionsSection infoCards={infoCards} />
                    </Element>
                    <Element name="contact">
                        <ContactSection
                            back="< back to projects"
                            backLink="/about-us/projects"
                            next="project 4 >"
                            nextLink="/about-us/projects/4"
                        />
                    </Element>
                </div>
            </ThemeProvider>
            <Footer />
        </>
    );
};

export default People;
