import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import Footer from 'ui-component/Footer';

import bg5 from '../../../assets/images/aq101-bg-5.webp';
import { Start, TableOfContents, Content, InfoCard, Scroll } from '../air-quality-101-subpage-theme';

const AQ101_Subpage = () => {
    return (
        <>
            <Helmet>
                <title>Importance of AQM | UP CARE</title>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    position: 'relative',
                }}
            >
                <Box>
                    {/* Start */}
                    <Start
                        title={"The tour's almost over. We're now at the Importance of AQM."}
                        text={
                            'Below, I will show you only some of the benefits we get from monitoring the air we breathe. Can you think of more?'
                        }
                    />
                    {/* TOC for small screens */}
                    <Box display={{ xs: 'flex', md: 'none' }}>
                        <TableOfContents />
                    </Box>
                    {/* Content */}
                    <Content
                        bg={bg5}
                        children={
                            <>
                                <InfoCard
                                    isTopBox={true}
                                    title={'Public Health Protection'}
                                    body={
                                        <>
                                            <br />
                                            Monitoring data helps issue health advisories, especially during high
                                            pollution episodes (e.g., smog or wildfire smoke). Children, elderly people,
                                            those with preexisting conditions, and those that live in areas with
                                            elevated air pollution levels are especially vulnerable to these negative
                                            health impacts.
                                            <br />
                                            <br />• <b>Short-term Effects:</b>
                                            <br />
                                            &nbsp;&nbsp;&nbsp;&nbsp;Irritation of eyes, nose, and throat, coughing, and
                                            shortness of breath.
                                            <br />
                                            <br />• <b>Long-term Effects:</b>
                                            <br />
                                            &nbsp;&nbsp;&nbsp;&nbsp;Respiratory diseases (asthma, bronchitis),
                                            cardiovascular diseases, and even cancer.
                                        </>
                                    }
                                />
                                <InfoCard
                                    variant={0}
                                    title={'Environmental Protection'}
                                    body={
                                        <>
                                            <br />
                                            With the data acquired from monitoring, we can identify and control
                                            pollution sources (vehicles, factories) to reduce harmful emissions. It
                                            enables us to address some sources that contribute to air pollution which
                                            can also mitigate climate change.
                                        </>
                                    }
                                />
                                <InfoCard
                                    title={'Policy Decisions'}
                                    body={
                                        <>
                                            <br />
                                            Governments use AQM data to regulate industries, limit vehicle emissions,
                                            and enforce clean air laws, reducing air pollution over time.
                                        </>
                                    }
                                />
                                <InfoCard
                                    variant={0}
                                    title={'Mitigation of Economic Consequences'}
                                    body={
                                        <>
                                            <br />
                                            The biggest air pollution-related damages are concentrated in the sectors of
                                            agriculture, utilities, manufacturing, and transportation. Premature death
                                            and disability caused by air pollution also lead to economic losses.
                                        </>
                                    }
                                />
                            </>
                        }
                    />
                    <Scroll />
                </Box>
                {/* TOC for large screens */}
                <Box display={{ xs: 'none', md: 'flex' }}>
                    <TableOfContents />
                </Box>
            </Box>
            <Footer />
        </>
    );
};

export default AQ101_Subpage;
