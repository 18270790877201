import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import LandingLayout from 'layout/LandingLayout';

// utilities routing
const Network = Loadable(lazy(() => import('views/air-aware-network')));
const Communities = Loadable(lazy(() => import('views/air-aware-communities')));
const Conference = Loadable(lazy(() => import('views/sample-page')));
const Diliman = Loadable(lazy(() => import('views/air-aware-communities//air-aware-diliman')));

// ==============================|| MAIN ROUTING ||============================== //

const BeInvolvedRoutes = {
    path: '/be-involved',
    element: <LandingLayout />,
    children: [
        {
            path: 'air-aware-network',
            element: <Network />,
        },
        {
            path: 'air-aware-communities',
            element: <Communities />,
        },
        {
            path: 'air-aware-communities/diliman',
            element: <Diliman />,
        },
        {
            path: 'air-aware-conference',
            element: <Conference />,
        },
    ],
};

export default BeInvolvedRoutes;
