import { Box, Button, createTheme, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link as LinkDOM } from 'react-router-dom';

import Backdrop2_Join from '../../assets/images/Backdrop2_Join.webp';
import AeonMascot from '../../assets/images/aeon-mascot.webp';

const useScreenBreakpoints = () => {
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
    const isMobileScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return { isDesktopScreen, isMediumScreen, isMobileScreen };
};

export default useScreenBreakpoints;

// Overall margin (in general)
export const marginDesktop = '8rem';
export const marginDesktopBetween = '4rem';
export const marginMobile = '2rem';

export const airqualityTheme = createTheme({
    palette: {
        primary: {
            main: '#00adb5',
            dark: '#00878d',
            contrastText: '#e69e4d',
        },
        secondary: {
            main: '#52584d',
            dark: '#000',
            contrastText: '#fff',
        },
        pastel: {
            pastelBlue: '#f2f9f9',
            pastelYellow: '#faf6ea',
            lightGray: 'f5f5f5',
        },
    },
    typography: {
        fontFamily: 'Lato, sans-serif', // Set Poppins as the default font, fallback to regular sans serif
    },
});

export const MapButton = ({ text, link, left, startIcon = null, endIcon = null }) => {
    return (
        <Button
            variant="contained"
            sx={{
                position: 'relative',
                left: { left },
                color: airqualityTheme.palette.secondary.contrastText,
                fontSize: { md: '1.15rem' },
                fontWeight: 700,
                borderRadius: 20,
                paddingX: { xs: '1.5rem', md: '2rem' },
            }}
            startIcon={startIcon}
            endIcon={endIcon}
            LinkComponent={LinkDOM}
            to={link}
        >
            {text}
        </Button>
    );
};

export const ContactButton = styled(Button)(({ theme }) => ({
    color: airqualityTheme.palette.primary.contrastText,
    fontWeight: 700,
    border: '1px solid #E69E4D',
    textTransform: 'uppercase',
    paddingLeft: '1.25rem',
    paddingRight: '1.25rem',
    minWidth: '20ch',
    '&:hover': {
        backgroundColor: '#CFCBC6',
    },
}));

export const PagerButton = styled(Button)(({ theme }) => ({
    color: airqualityTheme.palette.primary.contrastText,
    fontSize: '0.75rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
        fontSize: '0.85rem',
    },
    whiteSpace: 'nowrap',
    '&:hover': {
        backgroundColor: '#CFCBC6',
    },
}));

export const SectionTitleTypography = styled(Typography)(({ theme }) => ({
    fontSize: '1.5rem',
    marginBottom: '1rem',
    [theme.breakpoints.up('md')]: {
        fontSize: '1.8rem',
    },
    lineHeight: '125%',
    fontWeight: 600,
    color: airqualityTheme.palette.secondary.contrastText,
}));

export const SectionSubheadingTypography = styled(Typography)(({ theme }) => ({
    fontSize: '1.15rem',
    marginBottom: '1rem',
    [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
    },
    fontWeight: 600,
    color: airqualityTheme.palette.secondary.contrastText,
}));

export const ContactSection = ({ text, link }) => {
    return (
        <Box
            sx={{
                paddingY: { xs: marginMobile, md: marginDesktopBetween },
                paddingX: { xs: marginMobile, lg: marginDesktop },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: `url(${Backdrop2_Join})`,
                backgroundSize: 'cover',
                backgroundColor: 'lightgray 50%',
                gap: '1.25rem',
            }}
        >
            <Typography
                variant="body1"
                color="projectTheme.palette.secondary.main"
                textAlign="center"
                fontSize={{ xs: '1.25rem', sm: '1.5rem' }}
                fontWeight={600}
            >
                Like what you see?
            </Typography>
            <Box
                sx={{
                    fontSize: '1rem',
                    width: '100%',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: { xs: '1rem', md: '2rem' },
                }}
            >
                <ContactButton LinkComponent={LinkDOM} to={'/be-involved/air-aware-network'}>
                    Join Us
                </ContactButton>
                or
                <ContactButton LinkComponent={LinkDOM} to={'/contact-us'}>
                    Contact Us
                </ContactButton>
            </Box>
            <Typography
                sx={{
                    variant: 'body1',
                    fontSize: '1rem',
                    color: 'projectTheme.palette.secondary.main',
                    textAlign: 'center',
                    fontWeight: 400,
                    lineHeight: '30px',
                    marginBottom: '2rem',
                }}
            >
                for more information and partnership inquiries.
            </Typography>
            <PagerButton LinkComponent={LinkDOM} to={link}>
                {text}
            </PagerButton>
        </Box>
    );
};

export const Congrats = ({ textcolor }) => {
    const isMobileScreen = useScreenBreakpoints();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                paddingX: { xs: marginMobile, md: marginDesktopBetween },
                paddingTop: { xs: marginMobile, sm: marginDesktopBetween },
                paddingBottom: marginMobile,
                gap: '2rem',
            }}
        >
            <Box
                component="img"
                src={AeonMascot}
                alt="CARE Node"
                sx={{
                    width: { xs: '100px', md: '300px' },
                    objectFit: 'cover',
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: '1.25rem', md: '1.8rem' },
                        marginBottom: { xs: '1rem', md: '1.5rem' },
                        fontWeight: 600,
                        color: textcolor,
                    }}
                >
                    Congratulations! 🎉
                    <br />
                    You are now Air Aware.
                </Typography>
                <Typography
                    sx={{
                        color: textcolor,
                        maxWidth: '27ch',
                        fontSize: { xs: '1rem', lg: '1.25rem' },
                    }}
                >
                    But why stop here? {isMobileScreen && <br />} You can be a part of our community.
                </Typography>
            </Box>
        </Box>
    );
};
