import about from './about';
import beInvolved from './be-involved';
import followUs from './follow-us';
import keyEvents from './key-events';
import other from './other';
import aboutUs from './about-us';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [about, followUs, keyEvents, other, beInvolved, aboutUs]
};

export default menuItems;
