const other = {
    id: 'other',
    type: 'group',
    children: [
        {
            id: 'privacy-policy',
            title: 'Privacy Policy',
            type: 'item',
            url: '/privacy-policy',
            breadcrumbs: true
        },
        {
            id: 'terms-of-use',
            title: 'Terms of Use',
            type: 'item',
            url: '/terms-of-use',
            breadcrumbs: true
        },
    ]
};

export default other;
