import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Collapse, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

const NestedList = ({ title, menuItems }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItemButton
                sx={{
                    borderRadius: '0px',
                    alignItems: 'flex-start',

                    '&:hover': {
                        bgcolor: 'transparent',
                        borderBottom: '2px solid',
                        borderColor: 'grey.100',
                    },
                    mb: 0.5,
                    py: 1.25,
                    pl: '24px',
                }}
                onClick={handleClick}
                disableRipple
            >
                <ListItemText primary={<Typography color="grey.100">{title}</Typography>} />
                {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                {open ? (
                    <IconChevronUp stroke={2} size="1rem" color='white' style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                ) : (
                    <IconChevronDown stroke={2} size="1rem" color='white' style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {menuItems.map((item, i) => {
                        return (
                            <ListItemButton
                                key={i}
                                sx={{
                                    mb: 0.5,
                                    alignItems: 'flex-start',
                                    backgroundColor: 'inherit',
                                    py: 1.25,
                                    pl: `36px`,

                                    '&:hover': {
                                        bgcolor: 'transparent',
                                        borderBottom: '2px solid',
                                        borderColor: 'grey.100',
                                    },
                                }}
                                onClick={() => {
                                    if (item.type === 'external') {
                                        window.open(item.path, '_blank'); // Open the link in a new window/tab
                                    } else {
                                        navigate(item.path, { replace: false });
                                    }
                                }}
                                disableRipple
                                disabled={item.disabled}
                            >
                                <ListItemText primary={<Typography color="grey.100">{item.name}</Typography>} />
                            </ListItemButton>
                        );
                    })}
                </List>
            </Collapse>
        </>
    );
};

NestedList.propTypes = {
    title: PropTypes.string.isRequired,
    menuItems: PropTypes.array.isRequired,
};

export default NestedList;
