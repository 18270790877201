import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import Project1 from 'views/about/project1';
import Project4 from 'views/about/project4';
import Project5 from 'views/about/project5';
import LandingLayout from 'layout/LandingLayout';

// // utilities routing
const OurDNA = Loadable(lazy(() => import('views/about/our-dna')));
const People = Loadable(lazy(() => import('views/about/people')));
const Projects = Loadable(lazy(() => import('views/about/projects')));

// ==============================|| MAIN ROUTING ||============================== //

const AboutUsRoutes = {
    path: '/about-us',
    element: <LandingLayout />,
    children: [
        {
            path: 'our-dna',
            element: <OurDNA />,
        },
        {
            path: 'people',
            element: <People />,
        },
        {
            path: 'projects',
            element: <Projects />,
        },
        {
            path: 'projects/1',
            element: <Project1 />,
        },
        {
            path: 'projects/4',
            element: <Project4 />,
        },
        {
            path: 'projects/5',
            element: <Project5 />,
        },
    ],
};

export default AboutUsRoutes;
