import { lazy } from 'react';

// project imports
import LandingLayout from 'layout/LandingLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const LandingDefault = Loadable(lazy(() => import('views/landing/Default')));
// const LandingAirAware = Loadable(lazy(() => import('views/landing/AirAware')));
// ==============================|| MAIN ROUTING ||============================== //

const LandingRoutes = {
    path: '/',
    element: <LandingLayout />,
    children: [
        {
            path: '/',
            element: <LandingDefault />,
        },
    ],
};

export default LandingRoutes;
