import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import Footer from 'ui-component/Footer';

import bg1 from '../../../assets/images/aq101-bg-1.webp';
import { Start, TableOfContents, Content, InfoCard, Scroll } from '../air-quality-101-subpage-theme';

const AQ101_Subpage = () => {
    return (
        <>
            <Helmet>
                <title>Introduction to Air Pollution | UP CARE</title>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    position: 'relative',
                }}
            >
                <Box>
                    {/* Start */}
                    <Start
                        title={'Our first stop is Introduction to Air Pollution.'}
                        text={
                            'Air pollution refers to the presence of harmful substances in the air that pose risks to human health and the environment.'
                        }
                    />
                    {/* TOC for small screens */}
                    <Box display={{ xs: 'flex', md: 'none' }}>
                        <TableOfContents />
                    </Box>
                    {/* Content */}
                    <Content
                        bg={bg1}
                        children={
                            <>
                                <InfoCard
                                    isTopBox={true}
                                    title={'Air pollution can be of two types:'}
                                    body={
                                        <>
                                            <br />• <b>Outdoor Pollution:</b>
                                            <br />
                                            &nbsp;&nbsp;&nbsp;&nbsp;Mainly caused by transportation, industry, and
                                            urbanization.
                                            <br />
                                            <br />• <b>Indoor Pollution:</b>
                                            <br />
                                            &nbsp;&nbsp;&nbsp;&nbsp;Resulting from activities like cooking, smoking, and
                                            burning fuels indoors.
                                        </>
                                    }
                                />
                                <InfoCard
                                    variant={0}
                                    title={'Pollution can be human-made or natural.'}
                                    body={
                                        <>
                                            <br />• The primary sources of human-made air pollution are vehicle
                                            emissions, fuel oils and natural gas to heat homes, by-products of
                                            manufacturing and power generation, particularly coal-fueled power plants,
                                            and fumes from chemical production.
                                            <br />
                                            <br />• Nature releases hazardous substances into the air, such as smoke
                                            from wildfires, which are often caused by people; ash and gases from
                                            volcanic eruptions; and gases, like methane, which are emitted from
                                            decomposing organic matter in soils.
                                        </>
                                    }
                                />
                            </>
                        }
                    />
                    <Scroll />
                </Box>
                {/* TOC for large screens */}
                <Box display={{ xs: 'none', md: 'flex' }}>
                    <TableOfContents />
                </Box>
            </Box>
            <Footer />
        </>
    );
};

export default AQ101_Subpage;
